import React, { useState, useEffect } from 'react';
import { useDetectClickOutside } from "react-detect-click-outside";
import { FiLogOut } from 'react-icons/fi';
import { useUserActions } from 'actions/user';
import themeToggleLight from 'assets/images/theme-toggle-light.webp';
import themeToggleDark from 'assets/images/theme-toggle-dark.webp';
import './style.scss';
import ToggleSwitch from 'components/ToggleSwitch';
import { useAppSelector } from 'hooks/appSelector';
import { useDispatch } from 'react-redux';
import { setUser } from 'state/userSlice';

interface Props {
  email: string | undefined;
  firstname: string | undefined;
  surname: string | undefined;
  navbarExpanded: boolean | null;
}

const UserContainer: React.FC<Props> = ({
  email,
  firstname,
  surname,
  navbarExpanded
}) => {
  const [theme, setTheme] = useState('');
  const [intials, setInitials] = useState('');
  const userActions = useUserActions();
  const [isAccountOpened, setAccountOpened] = useState(false);
  const user = useAppSelector((state) => state.user);
  const [clustering, setClustering] = useState<boolean>(true);
  const dispatch = useDispatch()

  const getClustering = () => {
    const currentTenant = localStorage.getItem('tenantId');
    const cluster = localStorage.getItem(`clustering_${currentTenant}_${user?.username}`)
    if(cluster && user){
      setClustering(JSON.parse(cluster));
      dispatch(setUser({...user, clustering: JSON.parse(cluster)}))
    }
  }

  useEffect(() => {
    setTheme(userActions.getUserTheme());
    getClustering()
  }, []);

  useEffect(() => {
    if (surname && firstname) {
      setInitials(`${firstname.charAt(0).toLocaleUpperCase()}${surname.charAt(0).toLocaleUpperCase()}`);
    }
  }, [firstname, surname]);

  const changeTheme = () => {
    userActions.toggleUserTheme();
    const theme = userActions.getUserTheme();
    require(`@arcgis/core/assets/esri/themes/${theme}/main.css`);
    setTheme(theme);
    setAccountOpened(false);
  };

  const toggleUserContainer = (e: any) => {
    e.preventDefault();
    setAccountOpened(!isAccountOpened);
  };

  const closeUserContainer = () => {
    setAccountOpened(false);
  };

  const refUserContainer = useDetectClickOutside({ onTriggered: closeUserContainer });

  const toggleCluster = () => {
    setClustering(userActions.toggleClustering());
  };
  return (
    <div className={`xa-user-container${isAccountOpened ? ' user-opened' : ''}`} ref={refUserContainer} style={{ marginLeft: navbarExpanded ? '22.7rem' : '0' }}>
      <div className="xa-user-avatar" onClick={toggleUserContainer}>
        {intials}
      </div>
      <ul>
        <li className="xa-user-info">
          <span className="xa-user-name">{`${firstname} ${surname}`}</span>
          <span className="xa-user-email">{email}</span>
        </li>
        <li className="xa-user-tenant">
          <strong>Organisation:</strong> {localStorage.getItem('tenantId')?.replace(/['"]+/g, '')}
        </li>
        <li>
          <div className="xa-user-theme" onClick={changeTheme}>
            Theme:
            <img src={theme === 'dark' ? themeToggleDark : themeToggleLight} alt="theme-toggle" />
          </div>
        </li>
        <li>
          <div className="xa-user-theme">
            <ToggleSwitch onClick={toggleCluster} isOn={clustering} label={'Clustering'} />
          </div>
        </li>
        <li className="xa-user-logout" onClick={userActions.logout}>
          <FiLogOut />
          {' '}
          Sign out
        </li>
      </ul>
    </div>
  );
};

export default UserContainer;
