import dayjs from 'dayjs';
import { get, post } from 'utils/request';
import axios from 'axios';
import { StatisticsResponse } from './statistics';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface AssetInfo {
  assetTypeCode: string,
  name: string,
  number: string,
  sectionLabel: string,
  surveyDate: string,
  installationDate: string,
  lastUpdatedDate: string,
  startChainage: string,
  endChainage: string,
}

export interface AssetInspectionInfo {
  [x: string]: any;
  // assetTypeCode: string,
  inspectionType: string,
  frequencyName: string,
  frequency: number,
  overrideNextInspectionDate: string,
  overriddenReason: string,
  overdue: boolean,
  lastInspectionDate: string,
  nextInspectionDate: string,
}

export interface AssetAttributesInfo {
  name: string,
  value: string,
}

export interface AssetTypeAttributesInfo {
  group: string,
  attributeId: number,
  name: string,
  dataType: string,
  hasLookups: boolean,
  lookupValues: string[],
  id: number,
};

export interface AssetElementsInfo {
  id: number,
  name: string,
  spanNumber: number;
  assetId: number;
  assetNumber: string;
  default: boolean;
}

export interface AssetElementsRangeInfo {
  name: string,
  minValue: number,
  maxValue: number,
}

export interface EyeViDto {
  year: number;
  assetType: string;
}

export interface EyeViCredentialsDto {
  userName: number;
  password: string;
}

interface EyeViAuthorizationResponse {
  authorized: boolean;
  PHPSESSID: string;
}

const EYEVI_URL = `${process.env.REACT_APP_EYEVI_URL}/api/index.php?year=uk`;

const assetActions = () => {

  const getEyeViYears = async (refreshData: boolean = false): Promise<EyeViDto[]> => {
    const response = await get<EyeViDto[]>('config/eyevi/years', getRefreshDataHeader(refreshData));
    return response.data;
  }

  const getEyeViCredentials = async (assetType: string, year: number): Promise<Boolean> => {
    const response = await get<EyeViCredentialsDto>(`config/eyevi/cred/${assetType}`);
    const formatedYear = assetType === 'FW' ? `${year}footway` : `${year}`;
    const authResponse = await axios.post<any>(`${EYEVI_URL}${formatedYear}&request=login`, {
      username: response.data.userName,
      password: response.data.password,
    }, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
    });

    if (authResponse.data.authorized) {

      // domain=eyevi.xais.co.uk;secure; samesite=none
      document.cookie = `PHPSESSID=${authResponse.data.PHPSESSID}; path=/; domain=xais.co.uk`;
      return true;
    }
    return false;
  }

  const getAssetDetails = async (id: number, refreshData: boolean = false): Promise<AssetInfo> => {
    const response = await get<AssetInfo>(`assets/${id}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.surveyDate) responseData.surveyDate = dayjs(responseData?.surveyDate).format('YYYY-MM-DD');
    if (responseData.installationDate) responseData.installationDate = dayjs(responseData?.installationDate).format('YYYY-MM-DD');
    if (responseData.lastUpdatedDate) responseData.lastUpdatedDate = dayjs(responseData?.lastUpdatedDate).format('YYYY-MM-DD');
    return responseData;
  }

  const getAssetInspectionDetails = async (id: number, refreshData: boolean = false): Promise<AssetInspectionInfo> => {
    const response = await get<AssetInspectionInfo>(`assets/${id}/inspections/dates`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    responseData.map((item: any) => {
      if (item.lastInspectionDate) item.lastInspectionDate = dayjs(item?.lastInspectionDate).format('YYYY-MM-DD');
      if (item.nextInspectionDate) item.nextInspectionDate = dayjs(item?.nextInspectionDate).format('YYYY-MM-DD');
      if (item.overrideNextInspectionDate) item.overrideNextInspectionDate = dayjs(item?.overrideNextInspectionDate).format('YYYY-MM-DD');
    })
    return responseData;
  }

  const getAssetAttributes = async (id: number, refreshData: boolean = false): Promise<AssetAttributesInfo[]> => {
    const response = await get<AssetAttributesInfo[]>(`assets/${id}/attributes`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  }

  const getAssetElements = async (id: number, refreshData: boolean = false): Promise<AssetElementsInfo[]> => {
    const response = await get<AssetElementsInfo[]>(`assets/${id}/elements`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  }

  const getSpans = async (id: number, refreshData: boolean = false): Promise<number> => {
    const response = await get<number>(`assets/${id}/spans`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  }

  const getNetworkAttributes = async (attributeName: string, refreshData: boolean = false): Promise<any> => {
    const response = await get<any>(`visualiser/rules/attributes/networks?attributeName=${attributeName}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  };

  const getAssetTypeAttributes = async (category: string, type: string, itemKey: string, parameterId?: number): Promise<AssetTypeAttributesInfo[]> => {
    if (category.toLocaleLowerCase() === "performance") {
      const response = await get<AssetTypeAttributesInfo>(`visualiser/rules/attributes/performance/${itemKey.split('+')[1]}/years/${itemKey.split('+')[2]}`);
      const responseData = response.data;
      responseData.dataType = "Integer";
      return [responseData];
    }
    else if (category.toLocaleLowerCase() === 'analysis') {
      const responseData = {
        id: 1,
        name: "average",
        dataType: "Integer",
        hasLookups: false,
        lookupValues: [],
        year: itemKey.split('+')[2]
      } as any
      return [responseData];
    } else if (category.toLocaleLowerCase() === 'analysis schemes') {
      const response = await get<string[]>(`visualiser/rules/attributes/schemes/parameters/${parameterId}/treatments/years/${itemKey.split('+')[2]}`);
      const responseData = {
        id: 1,
        name: 'treatment',
        dataType: "String",
        hasLookups: true,
        lookupValues: response.data,
      } as any
      return responseData;
    } else if (category.toLocaleLowerCase() === 'candidate schemes') {
      const response = await get<string[]>(`visualiser/rules/attributes/schemes/fwps/years/${itemKey.split('+')[2]}`);
      const responseData = {
        id: 1,
        name: 'treatment',
        dataType: "String",
        hasLookups: true,
        lookupValues: response.data,
      } as any
      return responseData;
    } else if (category.toLocaleLowerCase() === 'programmed schemes') {
      const treatmentsResponse = await get<string[]>(`visualiser/rules/attributes/schemes/programmed/${parameterId}/years/${itemKey.split('+')[2]}/treatments`);

      const statusResponse = await get<string[]>(`visualiser/rules/attributes/schemes/programmed/${parameterId}/years/${itemKey.split('+')[2]}/status`);

      const responseData = [
        {
          id: 1,
          name: "cost",
          dataType: "Integer",
          hasLookups: false,
          lookupValues: [],
          year: itemKey.split('+')[2]
        },
        {
          id: 2,
          name: 'treatment',
          dataType: "String",
          hasLookups: true,
          lookupValues: treatmentsResponse.data,
        },
        {
          id: 3,
          name: 'status',
          dataType: "String",
          hasLookups: true,
          lookupValues: statusResponse.data,
        }
      ] as any
      return responseData
    } else {
      const response = await get<AssetTypeAttributesInfo[]>(`visualiser/rules/attributes/assets/${type}`);
      const responseData = response.data;
      return responseData;
    }
  }

  const getAssetTypeRange = async (category: string, id: number, parameterId?: number): Promise<AssetElementsRangeInfo> => {
    if (category.toLocaleLowerCase() === "performance") {
      const response = await get<AssetElementsRangeInfo>(`visualiser/rules/attributes/performance/attribute/${id}/range`);
      const responseData = response.data;
      return responseData;
    } else if (category.toLocaleLowerCase() === 'analysis') {
      const response = await get<AssetElementsRangeInfo>(`visualiser/rules/attributes/schemes/parameters/${parameterId}/years/${id}`);
      const responseData = response.data;
      return responseData;
    } else if (category.toLocaleLowerCase() === 'programmed schemes') {
      const response = await get<AssetElementsRangeInfo>(`visualiser/rules/attributes/schemes/programmed/${parameterId}/years/${id}/costs`);
      const responseData = response.data;
      return responseData;
    } else {
      const response = await get<AssetElementsRangeInfo>(`visualiser/rules/attributes/assets/attribute/${id}/range`);
      const responseData = response.data;
      return responseData;
    }
  }

  const getGraphData = async (id: number, requestType: string, refreshData: boolean = false): Promise<StatisticsResponse> => {
    const response = await get<StatisticsResponse>(`stats/assets/${id}/${requestType}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  }

  const getElementAttributes = async (id: number, spanNumber: number, elementId: number, refreshData: boolean = false): Promise<AssetElementsInfo[]> => {
    // const response = await get<AssetElementsInfo[]>(`assets/239601/spans/${spanNumber}/elements/99/attributes`);
    // const response = await get<AssetElementsInfo[]>(`assets/240163/spans/${spanNumber}/elements/388/attributes`);
    const response = await get<AssetElementsInfo[]>(`assets/${id}/spans/${spanNumber}/elements/${elementId}/attributes`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  }

  const getSpanInfo = async (id: number, spanNumber: number): Promise<any[]> => {
    const response = await get<any>(`assets/${id}/spans/${spanNumber}`);
    const responseData = response.data;
    return responseData;
  }


  return {
    getSpans,
    getEyeViYears,
    getAssetDetails,
    getAssetInspectionDetails,
    getAssetElements,
    getAssetTypeRange,
    getAssetAttributes,
    getEyeViCredentials,
    getNetworkAttributes,
    getAssetTypeAttributes,
    getGraphData,
    getElementAttributes,
    getSpanInfo
  };
};

export { assetActions };
