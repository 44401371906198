import React, { Fragment, useState, useEffect, Key, useRef } from 'react';
import { HiChevronRight, HiChevronDown, HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { NavBarSecondaryMenu } from './navbarData';
import Layers from 'components/Layers';
import Legend from 'containers/Legend';
import { RgbaColor } from "react-colorful";
import { SymbologyModelRecord } from 'models/SymbologyModel';
import { SymbologyModel } from 'models/SymbologyModel';
import { LayerAttributeStyle } from 'models/LayerAttributeStyle';
import { LayerRuleStyleRecord } from 'models/LayerRuleStyle';
import './style.scss';
import { FiRefreshCw } from 'react-icons/fi';
import { getLegendStructure } from "../../containers/Legend/getLegendStructure";
import { useLegendActions } from "actions/legend";

interface Props {
  onAction: (title: string, refreshLegendToolbar?: boolean) => void,
  activeActions: Record<string, boolean>,
  isExpanded: boolean | null,
  setIsExpanded: (value: boolean) => void,
  currentLayer: string,
  onLayerChange: (title: string) => void,
  onNodeChecked: (checkedNodes: Array<any>, currentSelectedNode?: any) => void,
  setLayerStyle: (key: string, color: RgbaColor, width: number, size: number, lineStyle: string, markerStyle: string, angle: number) => void,
  setLayerVisualisationRules: (key: string, type: string, style: SymbologyModel, layerAttributeStyles: LayerAttributeStyle[]) => void,
  zoomToSection: (key: string) => void,
  bringLayerToTop: (layerKey: string) => void,
  layersStyle: SymbologyModelRecord,
  layerRulesStyles: LayerRuleStyleRecord,
  zoomStackIsAllowed: boolean,
  layerRefreshLoading: never[],
  setLayerRefreshLoading: React.Dispatch<React.SetStateAction<never[]>>,
  checkedNodesState: [],
  setCheckedNodesState: (p: any[]) => void,
  handleContextMenuRefreshClicked: (selectedKeys: Key[]) => void,
  rightSidebarRef: React.RefObject<HTMLDivElement>,
  showDirection: boolean,
  setShowDirection: (value: boolean) => void,
}

const ToolBar: React.FC<Props> = ({
  onAction,
  activeActions,
  isExpanded,
  setIsExpanded,
  currentLayer,
  onLayerChange,
  onNodeChecked,
  setLayerStyle,
  setLayerVisualisationRules,
  bringLayerToTop,
  zoomToSection,
  layersStyle = {},
  layerRulesStyles = {},
  zoomStackIsAllowed,
  setCheckedNodesState,
  handleContextMenuRefreshClicked,
  checkedNodesState,
  setLayerRefreshLoading,
  layerRefreshLoading,
  rightSidebarRef,
  showDirection,
  setShowDirection
  }) => {
  const [isSubmenuOpened, setSubmenuOpened] = useState({} as { [k: string]: any });
  const legendActions = useLegendActions();
  const [toolbarRefreshLoading, setToolbarRefreshLoading] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);

  useEffect(() => {
    const navbarExpanded = localStorage.getItem('toolbarExpanded');
    const submenuState = localStorage.getItem('submenuState');
    setIsExpanded(navbarExpanded === 'true');
    if (submenuState) {
      setSubmenuOpened(JSON.parse(submenuState));
    }
  }, []);

  useEffect(() => {
    if (isExpanded !== null) {
      localStorage.setItem('toolbarExpanded', isExpanded.toString());
      localStorage.setItem('submenuState', JSON.stringify(isSubmenuOpened));
    }
   
  }, [isSubmenuOpened, isExpanded]);

  const toggleSubmenu = (event: React.MouseEvent<HTMLElement>, itemName: string) => {
    setSubmenuOpened(
      sub => {
        if (sub[itemName] !== undefined) {
          sub[itemName] = !sub[itemName]
        }
        else {
          sub[itemName] = true;
        }
        return {
          ...sub,
        };
      }
    );
  };

  const toggleNavBar = () => {
    if (isExpanded) {
      setIsExpanded(false);
      setSubmenuOpened({});
      return;
    }
    setIsExpanded(true);
  }

  const handleActionClick = (event: React.MouseEvent<HTMLElement>, title: string) => {
    event.preventDefault();
    if(title === "Legend" || title === "Basemaps") {
      setIsExpanded(true);
    }
    onAction(title);
  }

  const handleRefresh = async (e: React.MouseEvent<SVGElement, MouseEvent>, title: string) => {
    setToolbarRefreshLoading(true);
    e.stopPropagation();
    e.preventDefault();
    await refreshLegendStructure(legendActions, setTreeData);
  }
  const refreshLegendStructure = async (legendActions: { getLegend: any; }, setTreeData: {
    (value: React.SetStateAction<any[]>): void;
    (arg0: any[]): void;
  }) => {
    try {
      const legendResponse = await legendActions.getLegend(true);
      if (legendResponse) {
        setToolbarRefreshLoading(false);
      }
      const trData = getLegendStructure(legendResponse);
      const currentTenant = localStorage.getItem('tenantId');
      const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
      const savedTreeData = localStorage.getItem(`treeData_${currentTenant}_${currentUser?.username}`) as any;
      if (savedTreeData) {
        const parsedTreeData = JSON.parse(savedTreeData);
        trData.push(...parsedTreeData);
      }
      setTreeData(trData);
    } catch (err: any) {
    }
  }

  return (
    <div className={`xa-toolbar${isExpanded ? ' expanded' : ' collapsed'}`} ref={rightSidebarRef}>
      {NavBarSecondaryMenu.map((item) => {
        return (
          <Fragment key={item.title}>
            <div
              className={`xa-toolbar-item${(item.title === "Basemaps" || item.title === "Legend") ? (isSubmenuOpened[item.title] ? ' submenu-opened' : '') : ''}`}
              data-tip={item.title} data-for={item.title}>
              <NavLink
                to={item.path}
                className={(item.title === "Basemaps" || item.title === "Legend") ? '' : (() => activeActions[item.title] ? 'active' : '')}
                onClick={(e) => {
                  handleActionClick(e, item.title);
                  toggleSubmenu(e, item.title)
                }}
              >
                <span className="xa-toolbar-item-icon">{item.icon}</span>
                <span className="xa-toolbar-item-text">{item.title}</span>
                {item.title === 'Legend' && isSubmenuOpened.Legend &&
                  <FiRefreshCw onClick={e => handleRefresh(e, item.title)}
                               className={toolbarRefreshLoading ? 'rotating' : ''}/>
                }
                {(item.title === "Basemaps" || item.title === "Legend")
                  && <span className="xa-toolbar-item-parent-icon">{isSubmenuOpened[item.title] ? <HiChevronDown/> :
                    <HiChevronRight/>}
              </span>
                }
              </NavLink>
              <div className="xa-toolbar-submenu">
                {item.title === "Basemaps" &&
                  (
                    <Layers
                      currentLayer={currentLayer}
                      onLayerChange={onLayerChange}
                      zoomStackIsAllowed={zoomStackIsAllowed}
                    />
                  )}

                  {item.title === "Legend" &&
                  (
                    <Legend
                      onNodeChecked={onNodeChecked}
                      setLayerStyle={setLayerStyle}
                      setLayerVisualisationRules={setLayerVisualisationRules}
                      bringLayerToTop={bringLayerToTop}
                      zoomToSection={zoomToSection}
                      styles={layersStyle}
                      layerRulesStyles={layerRulesStyles}
                      setCheckedNodesState={setCheckedNodesState}
                      handleContextMenuRefreshClicked={handleContextMenuRefreshClicked}
                      setToolbarRefreshLoading={setToolbarRefreshLoading}
                      toolbarRefreshLoading={toolbarRefreshLoading}
                      setTreeData={setTreeData}
                      treeData={treeData}
                      checkedNodesState={checkedNodesState}
                      setLayerRefreshLoading={setLayerRefreshLoading}
                      layerRefreshLoading={layerRefreshLoading}
                      showDirection={showDirection}
                      setShowDirection={setShowDirection}
                    />
                  )}
                </div>
              </div>
              {!isExpanded &&
                <ReactTooltip
                  id={item.title}
                  place="left"
                  type="dark"
                  effect="solid"
                  backgroundColor="var(--xa-black)"
                  textColor="var(--xa-white)"
                />
              }
            </Fragment>
          )
        })}

      <div className="xa-toolbar-toggle" onClick={toggleNavBar}>
        <span className="xa-toolbar-toggle-icon">
          {isExpanded ? <HiChevronDoubleRight /> : <HiChevronDoubleLeft />}
        </span>
        <span className="xa-toolbar-toggle-text">Collapse</span>
      </div>
    </div>
  );
}

export default ToolBar;
