import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { TbArrowsMinimize, TbArrowsMaximize } from 'react-icons/tb';
import Loader from 'components/Loader';
import { useUserActions } from 'actions/user';
import { DisplayCodesResponse, GroupsResponse, statisticsActions, StatisticsResponse } from '../../actions/statistics';
import Draggable from 'react-draggable';
import { Rnd } from 'react-rnd';
import './style.scss';
import { FiRefreshCw } from "react-icons/fi";

export interface DataChart {
  id: string;
  value: string;
  customLabel: string;
}

interface Props {
  isOpen: boolean,
  closeModal: (title: string) => void,
}

const Charting: React.FC<Props> = ({
  isOpen = false,
  closeModal
}) => {
  const [theme, setTheme] = useState('');
  const [groupName, setGroupName] = useState<string | null>(null);
  const [groupCode, setGroupCode] = useState<string | null>(null);
  const userActions = useUserActions();
  const statistics = statisticsActions();
  const [groups, setGroups] = useState<GroupsResponse[]>();
  const [currentGroupStatus, setCurrentGroupStatus] = useState<GroupsResponse | undefined>();
  const [currentDisplayCode, setCurrentDisplayCode] = useState<DisplayCodesResponse | undefined>();
  const [displayCodes, setDislayCodes] = useState<DisplayCodesResponse[]>();
  const [groupStatus, setGroupStatus] = useState<StatisticsResponse>();
  const [loading, setLoading] = useState(true);
  const [isDisplayCodesVisible, setIsDisplayCodesVisible] = useState(false);
  const [dataChart, setDataChart] = useState<DataChart[]>();
  const [dataBarChart, setDataBarChart] = useState<BarDatum[]>();
  const [barChartKeys, setBarChartKeys] = useState<string[]>();
  const [barChartLabels, setBarChartLabels] = useState<Map<string, string>>();
  const [isPieChart, setIsPieChart] = useState<boolean>(true);
  const [isModalMinimized, setModalMinimized] = useState<boolean>(false);
  const [isGroup, setIsGroup] = useState<boolean>(true);
  const [chartingRefreshLoading, setChartingRefreshLoading] = useState<boolean>(false);
  const [groupIdForDropdown, setGroupIdForDropdown] = useState('');
  const [displayCodeFromDropdown, setDisplayCodeFromDropdown] = useState('');
  const [noDataText, setNoDataText] = useState<string | null>(null);
  const [groupHasData, setGroupHasData] = useState(false);


  const toggleChartType = () => setIsPieChart(prevState => !prevState);

  const toggleModalState = () => setModalMinimized(prevState => !prevState);

  useEffect(() => {
    setTheme(userActions.getUserTheme());
  }, []);

  useEffect(() => {
    const onGetGroups = async () => {
      setLoading(true);
      const groupsResponse = await statistics.getGroups();
      setGroups(groupsResponse);
      const displayCodesResponse = await statistics.getDisplayCodes();
      setDislayCodes(displayCodesResponse);
      setLoading(false);
    }
    onGetGroups();
  }, []);

  useEffect(() => {
    const dataChartAdapter = (): DataChart[] => {
      const data = [] as DataChart[];
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          data.push({
            id: group.key || group.label,
            value: group.value.toString(),
            customLabel: group.label,
          });
        });
      }
      return data as DataChart[];
    }
    const dataBarChartAdapter = (): BarDatum[] => {
      const data = [] as BarDatum[];
      const obj = {} as BarDatum;
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          obj[group.key || group.label] = group.value?.toString();
        });
        data.push(obj);

      }
      return data as BarDatum[];
    }
    const barChartKeysAdapter = (): string[] => {
      const data = [] as string[];
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          data.push((group.key || group.label)?.toString());
        });
      }
      return data as string[];
    }
    const barChartLabelsAdapter = (): Map<string, string> => {
      const data = new Map<string, string>();
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          data.set(`${group.key}` || `${group.label}`, `${group.label}`);
        });
      }
      return data as Map<string, string>;
    }
    setDataChart(dataChartAdapter);
    setDataBarChart(dataBarChartAdapter);
    setBarChartKeys(barChartKeysAdapter);
    setBarChartLabels(barChartLabelsAdapter);
  }, [groupStatus])

  const onGetGroupStatus = async (groupId: string, wasChartingRefreshClicked: boolean = false) => {
    if (groups != null) {
      setCurrentGroupStatus(groups.find((group) => group.id === Number(groupId)));
    }
    setLoading(true);
    if (wasChartingRefreshClicked) setChartingRefreshLoading(true);
    const groupStatusResponse = await statistics.getGroupStatus(Number(groupId), wasChartingRefreshClicked);
    if (groupStatusResponse) {
      setChartingRefreshLoading(false);
    }
    if (groupStatusResponse.status === 204) {
      setNoDataText(`No data available for the selected group`);
      setGroupHasData(false);
      setLoading(false);
      setChartingRefreshLoading(false);
      return;
    }
    setGroupHasData(true); 
    setGroupStatus(groupStatusResponse.data);
    setIsDisplayCodesVisible(false);
    setGroupName(null);
    setGroupCode(null);
    setIsGroup(true);
    setCurrentDisplayCode(undefined);
    setLoading(false);
  }

  const onGetDefaultTypeStats = async (data: any) => {
    if (!isGroup) {
      return;
    } else {
      setIsGroup(false);
    }
    setLoading(true);
    const defaultTypeStatsResponse = await statistics.getDefaultTypeStats(data.id);
    setGroupName(defaultTypeStatsResponse.name);
    setGroupCode(data.id);
    setGroupStatus(defaultTypeStatsResponse);
    setIsDisplayCodesVisible(true);
    setLoading(false);
  }

  const onGetTypeStats = async (displayCode: string, wasChartingRefreshClicked: boolean = false) => {
    setDisplayCodeFromDropdown(displayCode);
    const assetTypeCode = groupCode ?? '';
    if (displayCodes && displayCodes?.length > 0) {
      setCurrentDisplayCode(displayCodes.find((group) => group.code === displayCode));
    }
    setLoading(true);
    const typeStatsResponse = await statistics.getTypeStats(assetTypeCode, displayCode, wasChartingRefreshClicked);
    setGroupStatus(typeStatsResponse);
    setLoading(false);
  }

  const resetClasification = async () => {
    setLoading(true);
    const groupStatusResponse = await statistics.getGroupStatus(Number(currentGroupStatus?.id));
    setGroupStatus(groupStatusResponse.data);
    setIsDisplayCodesVisible(false);
    setGroupName(null);
    setIsGroup(true);
    setCurrentDisplayCode(undefined);
    setLoading(false);
  }

  if (currentGroupStatus?.id) {

    return (
      <Rnd
        className={`react-modal with-chart resizable top-left${isModalMinimized ? ' minimized' : ''}`} style={{ zIndex: 99 }}
        default={{
          x: 56,
          y: 8,
          width: "auto",
          height: "auto",
        }}
        bounds="body"
        dragHandleClassName="modal-header"
        enableResizing={{
          bottom: true,
          bottomLeft: true,
          bottomRight: true,
          left: true,
          right: true,
          top: true,
          topLeft: true,
          topRight: true
        }}
        maxWidth={900}
        minWidth={600}
      >
        <>
          <div className="modal-header">
            <div className="modal-title-container">
              <div className="modal-title-in-container" style={{textTransform: "uppercase"}}>Major asset group report</div>
              <FiRefreshCw
                className={chartingRefreshLoading ? "modal-refresh-button rotating" : "modal-refresh-button"}
                onClick={() => {
                  if (isDisplayCodesVisible) {
                    onGetTypeStats(displayCodeFromDropdown, true);
                  } else {
                    onGetGroupStatus(groupIdForDropdown, true)
                  }
                }
                }>
                Refresh
              </FiRefreshCw>
            </div>
            <div className="modal-header-buttons">
              <div className="modal-control" onClick={toggleModalState}>
                {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
              </div>
              <div className="modal-close" onClick={() => closeModal('Major asset group overview')}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            {loading && <Loader />}
            <form>
              <div className="xa-form-field">
                <label htmlFor="groups">Groups</label>
                <select name="groups" id="groups" disabled={loading} onChange={(e) => {
                  onGetGroupStatus(e.target.value)
                  setGroupIdForDropdown(e.target.value)
                }}>
                  {groups?.map((value) => (
                    <option value={value.id} key={value.id} selected={currentGroupStatus?.id === value.id}>
                      {value.name}
                    </option>))}
                </select>
              </div>
              {isDisplayCodesVisible && (
                <div className="xa-form-field">
                  <label htmlFor="type">Classification</label>
                  <select name="type" id="type" disabled={loading} onChange={(e) => onGetTypeStats(e.target.value)}>
                    {displayCodes?.map((value) => (
                      <option value={value.code} key={value.code} selected={currentDisplayCode?.code === value.code}>
                        {value.name}
                      </option>))}
                  </select>
                </div>
              )}
            </form>
            <div className="xa-form-field chart-selected">
              {groupName && (
                <div className="xa-chip">
                  <span>{groupName}</span>
                  <div className="xa-chip-reset" onClick={resetClasification}>
                    <MdClose />
                  </div>
                </div>
              )}
            </div>
            {!groupHasData ? 
            <div className="xa-chart-text">
              <p>
                {noDataText}
              </p>
            </div>
            :
            <div className='xa-chart'>
              {isPieChart && (
                <ResponsivePie
                  valueFormat=" >-,~f"
                   // @ts-ignore
                  tooltip={function (e) { return (<span style={{ padding: 12, color: e.datum.color, background: '#222222' }}>{e.datum.data.customLabel}: {e.datum.formattedValue}</span>) }}
                  key={`nivo-pie-${theme}`}
                   // @ts-ignore
                  data={dataChart ?? []}
                  onClick={(e: any) => onGetDefaultTypeStats(e)}
                  margin={{ top: 80, right: 200, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'darker',
                        0.2
                      ]
                    ]
                  }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor={'var(--xa-text)'}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'darker',
                        100
                      ]
                    ]
                  }}
                  defs={[
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'var(--xa-text)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                    }
                  ]}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 135,
                      // translateY: 56,

                      itemsSpacing: 5,
                      itemWidth: 80,
                      itemHeight: 15,
                      itemTextColor: 'var(--xa-text)',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      onClick: onGetDefaultTypeStats,
                    }
                  ]}
                  theme={{
                    tooltip: {
                      container: {
                        background: theme === 'dark' ? 'var(--xa-black)' : 'var(--xa-white)',
                      },
                    },
                  }}
                />
              )}
              {!isPieChart && (
                <ResponsiveBar
                  valueFormat=" >-,~f"
                  tooltip={function (e) { return (<span style={{ padding: 12, color: e.color, background: '#222222' }}>{barChartLabels?.get(`${e.id}`)}: {e.formattedValue}</span>) }}
                  key={`nivo-bar-${theme}`}
                  groupMode="grouped"
                  keys={barChartKeys}
                  data={dataBarChart ?? []}
                  onClick={(e: any) => onGetDefaultTypeStats(e)}
                  margin={{ top: 80, right: 180, bottom: 80, left: 80 }}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'darker',
                        0.2
                      ]
                    ]
                  }}
                  // labelTextColor={{

                  // }}
                  defs={[
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'var(--xa-text)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                    }
                  ]}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 135,
                      // translateY: 56,
                      itemsSpacing: 5,
                      itemWidth: 80,
                      itemHeight: 15,
                      itemTextColor: 'var(--xa-text)',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      onClick: onGetDefaultTypeStats,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: theme === 'dark' ? 'rgba(var(--xa-white-rgb), 0.2)' : 'rgba(var(--xa-black-rgb), 0.03)',
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ]}
                  theme={{
                    textColor: theme === 'dark' ? 'var(--xa-white)' : 'var(--xa-black)',
                    tooltip: {
                      container: {
                        background: theme === 'dark' ? 'var(--xa-black)' : 'var(--xa-white)',
                      },
                    },
                  }}
                />
              )}
            </div>
            }
          </div>
          <div className="modal-footer">
            <form className="custom-switch">
              <input
                type="radio"
                id="switch_left"
                name="switchToggle"
                value="Pie"
                onChange={toggleChartType}
                checked={isPieChart}
              />
              <label htmlFor="switch_left">Pie</label>

              <input
                type="radio"
                id="switch_right"
                name="switchToggle"
                value="Bar"
                onChange={toggleChartType}
                checked={!isPieChart}
              />
              <label htmlFor="switch_right">Bar</label>
            </form>
          </div>
        </>
      </Rnd>
    );
  }

  else {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => closeModal('Major asset group overview')}
        bodyOpenClassName={null}
        portalClassName="react-modal-container"
        overlayClassName="react-modal-overlay"
        className="react-modal-wrapper"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <Draggable
          enableUserSelectHack={false}
          handle=".modal-header"
          bounds="body"
        >
          <div className={`react-modal top-left${isModalMinimized ? ' minimized' : ''}`} style={{left: '28.5rem', transform: 'translate(0px, 0px)'}}>
            <div className="modal-header">
              <div className="modal-title">Major asset group</div>
              <div className="modal-close" onClick={() => closeModal('Major asset group overview')}>
                <MdClose />
              </div>
            </div>
            <div className="modal-content">
              {loading && <Loader />}
              <form>
                <label htmlFor="initial-groups">Groups</label>
                <select name="initial-groups" id="initial-groups" disabled={loading} onChange={(e) => {
                  onGetGroupStatus(e.target.value)
                  setGroupIdForDropdown(e.target.value)
                }}>
                  <option disabled selected={!currentGroupStatus} />
                  {groups?.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.name}
                    </option>))}
                </select>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </Draggable>
      </Modal>
    );
  }
}
export default Charting;
