import React, { useEffect, useInsertionEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import Login from 'containers/Login/index';
import Register from 'containers/Register/index';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import MainLayout from 'layouts/MainLayout/index';
import Dashboard from 'containers/Dashboard/index';
import { useUserActions } from 'actions/user';
import { instance } from 'utils/request';
import './App.scss';
import AdministrationTools from 'containers/AdministrationTools';
import { useNavigate } from 'react-router-dom';

const getRoutes = (isLoggedIn: boolean, pathname: string) => ([
  {
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" state={{ from: pathname }} />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/chart', element: <Dashboard /> },
      { path: '/administration-toolkit', element: <AdministrationTools /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  {
    path: '/login',
    element: <AuthLayout />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '*', element: <Navigate to="/login" /> },
    ],
  },
  {
    path: '/register',
    element: <AuthLayout />,
    children: [
      { path: '/register', element: <Register /> },
      { path: '*', element: <Navigate to="/register" /> },
    ],
  },
]);

const App: React.FC = (): JSX.Element => {
  const location = useLocation();
  const userActions = useUserActions();
  const navigate = useNavigate();
  const isLoggedIn = userActions.restoreUser(); 
  const routing = useRoutes(getRoutes(isLoggedIn, location.pathname));

  useInsertionEffect(() => {
    userActions.getUserTheme();
  }, []);

  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();

    return () => window.removeEventListener('resize', documentHeight);
  }, []);

  useEffect(() => {
    let isRedirecting = false;

    const interceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!isRedirecting && (error?.response?.status === 401 || error?.response?.status === 403)) {
          isRedirecting = true;
          userActions.logout(); 
          navigate('/login', { replace: true }); 
          isRedirecting = false; 
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.response.eject(interceptor);
    };
  }, [userActions, navigate]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const isLoggedIn = userActions.restoreUser();
      if (!isLoggedIn) {
        navigate('/login');
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 1800000); // Check every 30 minutes

    return () => clearInterval(intervalId);
  }, [userActions, navigate]);

  return <>{routing}</>;
};

export default App;
