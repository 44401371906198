import { useNavigate, useLocation } from "react-router-dom";
import type { FormValues } from 'models/Login';
import { useDispatch } from 'react-redux';
import { setUser } from 'state/userSlice';
import { get, post, setHeader } from 'utils/request';
import { RegisterUser } from "models/Register";
import { UserDetails } from "models/UserDetails";
import { setTheme } from "state/themeReducer";
import {jwtDecode} from 'jwt-decode';

export interface LoginResponse {
  username: string,
  token: string,
}

export interface RegisterResponse {
  username: string,
  token: string,
}

interface RouteState {
  pathname?: string,
}

interface DecodedToken {
  exp: number;
  // ...other properties...
}

const useUserActions = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch()

  const register = async (model: RegisterUser): Promise<any> => {
    await post<RegisterUser, string>('account/register', {
      username: model.username,
      firstname: model.firstname, surname: model.surname,
      email: model.email, password: model.password,
      tenantId: model.tenantId,
    });

    const state = location.state as RouteState;
    navigate(state?.pathname || '/login', {
      state: 'fromRegister'
    });
  }

  const getTenants = async (): Promise<Array<string>> => {
    const response = await get<Array<string>>('account/tenants');
    return response.data;
  }

  const login = async (model: FormValues): Promise<any> => {
    const response = await post<FormValues, LoginResponse>('account/login', { username: model.username, password: model.password, tenantId: model.tenantId });

    // store jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('tenantId', JSON.stringify(model.tenantId))

    setHeader('Authorization', `Bearer ${response.data.token}`);

    const user = await get<UserDetails>(`users/${response.data.username}`);
    const cluster = localStorage.getItem(`clustering_${JSON.stringify(model.tenantId)}_${response.data.username}`)
    if(!cluster){
      localStorage.setItem(`clustering_${JSON.stringify(model.tenantId)}_${response.data.username}`, 'true');
    }

    // store user details in local storage to keep user logged in between page refreshes
    localStorage.setItem('userData', JSON.stringify(user.data));

    dispatch(setUser({
      ...response.data,
      ...user.data,
    }));
    const state = location.state as RouteState;
    navigate(state?.pathname || '/');
  }


  const clearAndRestoreLocalStorage = () => {
    const keysToRemove = ['userData', 'toolbarExpanded', 'token', 'submenuState', 'navbarExpanded'];
    keysToRemove.forEach(key => {
      localStorage.removeItem(key);
    });
  }

  const isTokenExpired = (token: string): boolean => {
    try {
      const decoded: DecodedToken = jwtDecode(token);
      const currentTime =  Math.floor(Date.now() / 1000);
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  const restoreUser = (): boolean => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('userData');

    if (token && user) {
      if (isTokenExpired(token)) {
        clearAndRestoreLocalStorage();
        return false;
      }

      const userData: UserDetails = JSON.parse(user);
      setHeader('Authorization', `Bearer ${token}`);
      dispatch(setUser({
        token,
        ...userData,
      }));
      return true;
    }
    clearAndRestoreLocalStorage();
    return false;
  };

  const logout = () => {
    clearAndRestoreLocalStorage()
    navigate('/login');
  }

  const getUserTheme = (): string => {
    let theme = 'dark';
    if (localStorage.getItem('theme') && localStorage.getItem('theme') === 'dark') {
      if (!document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.add('dark');
      }
    } else if (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (!document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.add('dark');
      }
      localStorage.setItem('theme', 'dark');
      dispatch(setTheme({ theme: 'dark'}))
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
      }
      localStorage.setItem('theme', 'light');
      theme = 'light';
      dispatch(setTheme({ theme: 'light'}))
    }
    return theme;
  }

  const toggleUserTheme = () => {
    const theme = getUserTheme();
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
    dispatch(setTheme({ theme: theme === 'light' ? 'dark' : 'light'}))
    getUserTheme();
  }

  const toggleClustering = (): boolean => {
    const user = localStorage.getItem('userData');
    const currentTenant = localStorage.getItem('tenantId');
    if (!user || !currentTenant) return true;
    const userData: UserDetails = JSON.parse(user);
    const clustering = localStorage.getItem(`clustering_${currentTenant}_${userData?.username}`)

    if (clustering) {
      if (JSON.parse(clustering)) {
        localStorage.setItem(`clustering_${currentTenant}_${userData?.username}`, 'false');
        dispatch(setUser({ ...userData, clustering: false }))
        return false;
      } else {
        localStorage.setItem(`clustering_${currentTenant}_${userData?.username}`, 'true');
        dispatch(setUser({ ...userData, clustering: true }))
        return true;
      }
    } else {
      localStorage.setItem(`clustering_${currentTenant}_${userData?.username}`, 'false');
      dispatch(setUser({ ...userData, clustering: false }))
      return false;
    }
  }

  return {
    login,
    restoreUser,
    logout,
    getUserTheme,
    toggleUserTheme,
    register,
    getTenants,
    toggleClustering
  };

};

export { useUserActions };
