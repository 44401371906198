import React from 'react';
import './style.scss';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  color?: string, // primary, secondary, text, action, danger
  className?: string,
  submit?: boolean,
  full?: boolean,
  disabled?: boolean;
  isButton?: boolean;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  color = 'primary',
  className = 'xa-button',
  submit = false,
  full = false,
  disabled = false,
  isButton = false,
}) => {
  let btnClass = `${className}`;
  if (color === "primary")
    btnClass += " primary";
  else if (color === "secondary")
    btnClass += " secondary";
  else if (color === "danger")
    btnClass += " danger"
  else if (color === "text")
    btnClass += " text";
  else if (color === "action")
    btnClass += " action";
  else if (color === "danger")
    btnClass += " danger";
  else if (color === "update")
    btnClass += " update";
  if (full) btnClass += " full-width";
  if (disabled) btnClass += " disabled";

  if (submit || isButton) {
    return (
      <button
        className={btnClass}
        type={submit ? "submit" : undefined}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return (
    <div className={btnClass} onClick={onClick}>
      {children}
    </div>
  );
};

export default Button;
