import React from 'react'
import AttributeRow from '../../attributeRow';
import EmptyRequestDisplay from '../AssetHubNoData';

type AssetHubInfoProps = {
  modalDetails: any;
  spanInfoError: boolean
};

const AssetHubInfo: React.FC<AssetHubInfoProps> = ({ modalDetails, spanInfoError }) => {
// console.log(modalDetails);

  return <div>
    {spanInfoError ? <div className='xa-asset_hub--info_container empty_request--container'><EmptyRequestDisplay /></div> :
      <div className="xa-attributes-list xa-asset_hub--info_container">
        {/* List head */}
        {modalDetails !== undefined && modalDetails != null ? <div className="xa-attributes-list-head">
          <div className="xa-attributes-list-label">Name</div>
          <div className="xa-attributes-list-value">Value</div>
        </div> : <div  className="xa-asset_hub--info_container empty_request--container" />}


        {/* List rows */}
        {/* Attributes is clicked, we will render the attributes array */}
        {(modalDetails !== undefined && modalDetails != null && Array.isArray(modalDetails)) ?
          modalDetails
            .map((att: any) => (
              <AttributeRow
                name={att.name}
                value={att.value}
                key={att.name}
              />
            ))
          :
          (modalDetails !== undefined && modalDetails != null) ? Object.keys(modalDetails)
            .filter((k) => k !== 'geometry' && k !== 'attributes'
              && k !== 'elements' && k !== 'segmentable' && k !== 'parent' && k !== 'key' && k !== 'title')
            .map((key) => (
              <AttributeRow
                name={key}
                value={modalDetails[key]}
                key={key}
              />
            )) : <></>
        }
      </div>}
  </div>
}
export default AssetHubInfo;